export default defineNuxtRouteMiddleware(async ({ name, params }) => {
  const localePath = useLocalePath()
  const locales = ['en', 'fr']
  const locale = name.substr(-2)

  if (!locales.includes(locale)) {
    return navigateTo(
      localePath('resources'),
      {
        redirectCode: 301,
      },
    )
  }

  const { $slugify } = useNuxtApp()
  const { data } = await useFetch(`/api/content/pages/resources-index/${locale}`, {
    key: `middleware-resource-category-[slug]-${params.slug}-${locale}`,
    transform: (input) => {
      const { allModelOfferTypes } = input
      const page = allModelOfferTypes
        .map(({ name }) => $slugify(name))
        .find(type => type === params.type)
      return {
        page,
      }
    },
  })

  if (!data.value.page) {
    return navigateTo(
      localePath('resources'),
      {
        redirectCode: 301,
      },
    )
  }
})
